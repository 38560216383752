import React, { useEffect } from "react";
import SEO from "../components/SEO";
import HeroSectionExp from "@src/components/partials/beyondschool/HeroSectionExp";
import PlusPageContent from "@src/components/partials/plus/PlusPageContent";
import { useLocation } from "@reach/router";

const searchToObject = (search) => {
  const paramArray = search.split("&");
  const paramObject = paramArray.reduce((acc, cur) => {
    const keyValuePair = cur.split("=");
    acc[keyValuePair[0]] = keyValuePair[1];
    return acc;
  }, {});
  return paramObject;
};

const BeyondSchoolPage: React.FC = () => {
  const location = useLocation();
  const queryParams = searchToObject(location.search.slice(1));

  useEffect(() => {
    if (queryParams && queryParams.entrypoint === "premiumMessagingPresale") {
      window.location.replace("https://home.classdojo.com/#/messages?openModal=premiumMessagingPresale");
    }
    if (queryParams && queryParams.entrypoint === "biannual") {
      window.location.replace("https://home.classdojo.com/#/subscription/biannual-promo");
    }
  }, [queryParams]);

  const HeroSectionExpProps = {
    queryParams: queryParams,
  };

  // this because /beyond-school cannot be redirected in the server with searchParameters
  const SEOProps = {
    noindex: true,
    title: "ClassDojo Plus",
    description: "ClassDojo Plus gives you more ways to support, celebrate and connect with your child.",
    twitter: {
      card: "summary_large_image",
      site: "@classdojo",
      creator: "@classdojo",
      title: "Never miss a moment with ClassDojo Plus",
      description: "ClassDojo Plus gives you more ways to support, celebrate and connect with your child.",
      image: "https://static.classdojo.com/img/page_plus/plus-card.png",
    },
  };

  return (
    <>
      <SEO {...SEOProps} />
      <HeroSectionExp {...HeroSectionExpProps} />
      <PlusPageContent isBeyondSchoolBanner={true} />
    </>
  );
};

export default BeyondSchoolPage;
